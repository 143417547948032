/* eslint eqeqeq: "off" */
import React, { useState, useCallback, useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../ui/Layout"
import connectStore from "../state/connectStore"
import OrderSummaryCard from "../ui/OrderSummaryCard"
import { submitOrder as doSubmitOrder } from "../util/OrderUtil"
import {
  Button,
  Message,
  Divider,
  Icon,
} from "semantic-ui-react"
import Color from "../style/Color"

const onNext = () => navigate("/order-status", { replace: true });

const toFailureMessage = failureReason => {
  const { unavailable=false, invalidName=false } = failureReason;
  const reasons = ["Oops!"];
  if(unavailable) {
    reasons.push("One or more ingredients are currently out of stock. Please try remaking your order.");
  }
  if(invalidName) {
    reasons.push("Your name is invalid. Please enter a new name and try again.");
  }
  if(!unavailable && !invalidName) {
    reasons.push("The order was rejected. Please try remaking your order.");
  }
  return reasons.join(" ");
};

const toRedoLocation = failureReason => {
  const { unavailable=false, invalidName=false } = failureReason;
  if(!unavailable && invalidName) {
    return "/order-name";
  }
  return "/order-cookie";
};

const toRedoLocationText = failureReason => {
  const { unavailable=false, invalidName=false } = failureReason;
  if(!unavailable && invalidName) {
    return "Enter Name";
  }
  return "Remake Order";
};

const RedoMessage = ({ failureReason }) => (
  <>
    <Divider hidden/>
    <Message color={Color.accent} error>
      {toFailureMessage(failureReason)}
      <br/><br/>
      <Button compact color={Color.accent} onClick={() => navigate(toRedoLocation(failureReason), { replace: true })}>{toRedoLocationText(failureReason)}</Button>
    </Message>
  </>
);

// {...order, cookie: null, dryToppings: [], wetToppings: []} }

// This is for protection to keep us from submitting more than once, like by double
// clicking the button
let submitCount = 0;

const Page = ({ location, order, submitOrder }) => {
  const [failureReason, setFailureReason] = useState(null);
  const hasCookie = order.cookie != null;
  const hasName = order.customerName != null && order.customerName.length > 0;

  // Submit our order to the server, then if successful, add the submitted 
  // order to our state. If it is not accepted, show the "redo" message.
  const onSubmit = useCallback(() => {
    // Do some extra order validation before sending the order out just so the
    // server doesn't have to catch it.
    if(!hasName && !hasCookie) {
      setFailureReason({ unavailable: true, invalidName: true });
      return;
    } else if(!hasName) {
      setFailureReason({ invalidName: true });
      return;
    } else if(!hasCookie) {
      setFailureReason({ unavailable: true });
      return;
    }

    if(submitCount == 0) {
      submitCount++;
      doSubmitOrder(order, (submittedOrder, accepted, unavailable, invalidName) => {
        if(!accepted) {
          setFailureReason({ unavailable, invalidName });
          submitOrder({...order, cookie: null, dryToppings: [], wetToppings: []});
        } else {
          submitOrder(submittedOrder);
        }
        submitCount--;
      });
    }
  }, [order, setFailureReason, submitOrder, hasName, hasCookie]);

  // As soon as our order is considered submitted, go to the next page
  useEffect(() => {
    if(order.submittedOrderAt != null) {
      onNext();
    }
  }, [order]);

  return (
    <Layout
      location={location}
      to="/order-complete"
      header={<><Icon name="shopping cart"/>Your Shopping Cart</>}
      validate={failureReason == null}
      footer={failureReason != null ? null : <Button onClick={onSubmit} color={Color.accent} size="huge"><Icon name="check circle"/>Place Order</Button>}
    >
      { failureReason != null ?
        <RedoMessage failureReason={failureReason}/>
        :
        <OrderSummaryCard order={order} hideStatus/>
      }
    </Layout>
  );
};

export default connectStore(Page);
